import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, DatePicker, Form, Row, Select, TimePicker } from "antd";
import heroImage from "../assets/images/pexels-deva-darshan-1173777.jpg";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import FormItemLabel from "antd/es/form/FormItemLabel";
import dayjs from "dayjs";
import "./custom-timepicker.css";
import CustomTimePicker from "./CustomTimePicker";

const format = "HH:mm";

const { Option } = Select;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const CarRentalHero = () => {
  const [form] = Form.useForm();
  const history = useNavigate();

  const onFinish = async () => {
    const values = await form.validateFields();
    console.log("Received values of form: ", values);
    const pickupDate = dayjs(values.pickupDate).format("YYYY-MM-DD");
    const returnDate = dayjs(values.returnDate).format("YYYY-MM-DD");
    const pickupTime = dayjs(values.pickupTime).format("HH:mm");
    const pickupLocation = values.pickupLocation;

    history(
      `/search/results?pickupLocation=${pickupLocation}&pickupDate=${pickupDate}&returnDate=${returnDate}&pickupTime=${pickupTime}&carType=any`
    );
  };

  return (
    <div id="home" className="relative py-20 ">
      <div className="absolute inset-0">
        <img className="w-full h-full object-cover" src={heroImage} alt="" />
        <div
          className="absolute inset-0 bg-gray-500"
          style={{ mixBlendMode: "multiply" }}
        ></div>
      </div>

      <div className="relative container mx-auto px-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="mb-12 md:mb-0">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
              Logistics & Car Hiring
            </h1>
            <p className="mt-6 text-xl text-gray-300 max-w-3xl">
              Malz Logistics is a top-notch logistics and car rental company
              committed to providing exceptional services to our clients. Our
              focus is on providing reliable and efficient logistics services,
              car rentals, and transportation solutions for businesses and
              individuals. With our team of experienced professionals, we strive
              to exceed our customers' expectations and provide them with the
              best possible experience.
            </p>
            <div className="mt-10">
              <Link
                to="/services"
                className="text-base font-medium text-white bg-blue-600 hover:bg-blue-500 px-6 py-3 rounded-md transition-colors duration-300"
              >
                Our Services
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </Link>
            </div>
          </div>
          <div className="bg-cover bg-center backdrop-blur-md rounded-lg shadow-lg p-6 md:w-1/3">
            <h2 className="text-2xl font-bold mb-4 text-white">Book Now</h2>
            <Form layout="vertical" form={form} name="quick_booking">
              <Row gutter={[16, 0]} className="" row-gap={0}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="pickupDate"
                    label={<span style={{ color: "white" }}>Pickup Date</span>}
                    rules={[{ required: true }]}
                  >
                    <DatePicker className="w-full" format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="pickupTime"
                    label={<span style={{ color: "white" }}>Pickup Time</span>}
                    rules={[{ required: true }]}
                  >
                    <TimePicker className="w-full" format={format} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={<span style={{ color: "white" }}>Return Date</span>}
                    name="returnDate"
                    rules={[{ required: true }]}
                  >
                    <DatePicker className="w-full" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="pickupLocation"
                    className="text-white"
                    label={
                      <span style={{ color: "white" }}>Pickup Location</span>
                    }
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="w-full text-white"
                      placeholder="Select Pickup Location"
                    >
                      <Option value="Airport">Airport</Option>
                      <Option value="Rhodespark">Rhodespark Office</Option>
                      <Option value="Town">Town</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full bg-blue-600 hover:bg-blue-500"
                  onClick={onFinish}
                >
                  <SearchOutlined />
                  Search
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarRentalHero;
