import { useNavigate } from "react-router-dom";
import CarRentalSearchResults from "../components/CarRentalSearchResults";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import SearchForm from "../components/SearchForm";

const SearchResults = () => {
  const history = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);

  const pickupLocation = urlSearchParams.get("pickupLocation");
  const pickupDate = urlSearchParams.get("pickupDate");
  const returnDate = urlSearchParams.get("returnDate");
  const pickupTime = urlSearchParams.get("pickupTime");
  const carType = urlSearchParams.get("carType");

  if (!pickupLocation || !pickupDate || !returnDate || !pickupTime) {
    console.log("No search parameters found");

    history("/home");
  }

  console.log("pickupLocation: " + pickupLocation);
  console.log("pickupDate: " + pickupDate);
  console.log("returnDate: " + returnDate);
  console.log("pickupTime: " + pickupTime);
  console.log("carType: " + carType);

  return (
    <>
      <NavBar />
      <SearchForm
        initialValues={{
          pickupLocation: "",
          pickupDate: "",
          returnDate: "",
          pickupTime: "",
          carType: "",
        }}
        onSubmit={function (values: any): void {
          throw new Error("Function not implemented.");
        }}
      />
      <CarRentalSearchResults />
      <Footer />
    </>
  );
};

export default SearchResults;
