import React from "react";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
};

function CategoryCard({ title, description, imageUrl }: Props) {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg m-2 bg-white">
      <div className="flex">
        <div className="flex-1 p-4">
          <div className="font-bold text-lg mb-2 text-gray-800">{title}</div>
          <p className="text-gray-700 text-xs">{description}</p>
          <div className="mt-4">
            <Link
              to="/book-now"
              className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-3 rounded-full text-xs"
            >
              Book Now
            </Link>
          </div>
        </div>
        <div className="flex-1 pr-4 pt-5">
          <img className="object-cover" src={imageUrl} alt="Car" />
        </div>
      </div>
    </div>
  );
}

export default CategoryCard;
