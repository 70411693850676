import React from "react";

type Props = {};

function MoreInfo({}: Props) {
  return (
    <section className="py-24">
      <div className="container mx-auto px-4 space-y-12">
        <div className="flex flex-wrap items-center">
          <div className="py-4 w-full lg:w-6/12">
            <img
              src="https://images.unsplash.com/photo-1493238792000-8113da705763?ixid=MnwyMDkyMnwwfDF8c2VhcmNofDJ8fGNhcnxlbnwwfHx8fDE2MzE2NzIwOTU&ixlib=rb-1.2.1q=85&fm=jpg&crop=faces&cs=srgb&w=1000&h=650&fit=crop"
              width="1000"
              alt="..."
              height="650"
            />
          </div>
          <div className="py-4 w-full lg:w-6/12">
            <div className="bg-white lg:-ml-12 lg:pl-12 lg:py-12">
              <h2 className="mb-2 text-primary-500 text-xl">
                Rental Cars &amp; Much More
              </h2>
              <h3 className="capitalize font-bold mb-4 text-4xl text-gray-900 xl:text-5xl">
                Experience a Rental service like never before
              </h3>
              <div className="bg-primary-500 mb-6 pb-1 w-2/12"></div>
              <p className="mb-6">
                Phasellus pulvinar faucibus neque, nec rhoncus nunc ultrices sit
                amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus pulvinar faucibus neque, nec rhoncus nunc ultrices sit
                amet. Curabitur ac sagittis neque, vel egestas est.
              </p>
              <a
                href="#"
                className="bg-blue-600 hover:bg-blue-500 inline-block px-6 py-2 text-white"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="py-4 w-full lg:w-4/12 xl:mr-auto xl:w-3/12">
            <h4 className="capitalize font-bold mb-2 text-3xl text-gray-900">
              Our Service is Built With Trusr
            </h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="py-4 w-full lg:w-8/12">
            <div className="-mx-4 flex flex-wrap">
              <div className="p-4 w-full md:w-4/12">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 65 65"
                    xmlSpace="preserve"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    stroke="currentColor"
                    width="5rem"
                    height="5rem"
                    className="h-20 mb-3 text-blue-600 w-20"
                  >
                    <path
                      d="M13 50v-1.849a2.73 2.73 0 0 0-.741-1.87l-4.038-4.293A4.499 4.499 0 0 1 7 38.907V21.998A2.998 2.998 0 0 1 9.998 19h.004A2.999 2.999 0 0 1 13 21.998V27m38 23v-1.849c0-.695.265-1.364.741-1.87l4.038-4.293A4.499 4.499 0 0 0 57 38.907V21.998A2.998 2.998 0 0 0 54.002 19h-.004A2.999 2.999 0 0 0 51 21.998V27M29 56.379v-4.933c0-.383-.152-.751-.424-1.022A1.442 1.442 0 0 0 27.554 50H13.446c-.383 0-.751.152-1.022.424A1.442 1.442 0 0 0 12 51.446v4.933m23 0v-4.933c0-.383.152-.751.424-1.022A1.442 1.442 0 0 1 36.446 50h14.108c.383 0 .751.152 1.022.424.272.271.424.639.424 1.022v4.933"
                      fill="none"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M28 50v-5.491c0-2.439-1-4.771-2.767-6.453l-7.49-7.13a2.71 2.71 0 0 0-3.785.047l-.002.002a2.767 2.767 0 0 0 0 3.912l4.527 4.527M36 50v-5.491c0-2.439 1-4.771 2.767-6.453l7.49-7.13a2.71 2.71 0 0 1 3.785.047l.002.002a2.767 2.767 0 0 1 0 3.912l-4.527 4.527M22.25 16.464a1.499 1.499 0 0 1 1.5-1.5h3.75a1.5 1.5 0 0 0 1.5-1.5V9.5A1.5 1.5 0 0 1 30.5 8h3A1.5 1.5 0 0 1 35 9.5v3.964a1.5 1.5 0 0 0 1.5 1.5h3.75a1.5 1.5 0 0 1 1.5 1.5V19.5a1.503 1.503 0 0 1-1.5 1.5H36.5a1.503 1.503 0 0 0-1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 0 1-1.5-1.5v-4a1.503 1.503 0 0 0-1.5-1.5h-3.75a1.503 1.503 0 0 1-1.5-1.5v-3.036z"
                      fill="none"
                      strokeWidth="2"
                    ></path>
                  </svg>
                  <h4 className="font-bold text-gray-900 text-xl">
                    Insured &amp; Safe
                  </h4>
                </div>
              </div>
              <div className="p-4 w-full md:w-4/12">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 65 65"
                    xmlSpace="preserve"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    stroke="currentColor"
                    width="5rem"
                    height="5rem"
                    className="h-20 mb-3 text-blue-600 w-20"
                  >
                    <path
                      d="M49.972 21.334c.003-2.264.011-4.339.028-5.976a2.574 2.574 0 0 0-1.082-2.109 2.596 2.596 0 0 0-2.344-.351m-.249.334-.002.001m-16.987-.534a17.589 17.589 0 0 1-11.907.2l-.003-.001A2.596 2.596 0 0 0 14 15.358v18.717m17.455 18.167 11.83-6.799"
                      fill="none"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M54 14.908a6.172 6.172 0 0 0-8.146-5.848l-.002.001a14.905 14.905 0 0 1-9.999-.166l-2.623-.984a3.507 3.507 0 0 0-2.46 0l-2.623.984a14.905 14.905 0 0 1-9.999.166l-.002-.001A6.172 6.172 0 0 0 10 14.908V34.14a17.257 17.257 0 0 0 8.263 14.728l11.35 6.93a3.504 3.504 0 0 0 3.573.048l12.156-6.986A17.256 17.256 0 0 0 54 33.898v-18.99z"
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="butt"
                    ></path>
                    <circle
                      cx="32"
                      cy="26"
                      r="5"
                      fill="none"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M21.169 40.5C23.64 36.554 27.574 34 32 34c4.426 0 8.36 2.554 10.831 6.5"
                      fill="none"
                      strokeWidth="2"
                    ></path>
                  </svg>
                  <h4 className="font-bold text-gray-900 text-xl">
                    Certified Chauffeur
                  </h4>
                </div>
              </div>
              <div className="p-4 w-full md:w-4/12">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 65 65"
                    xmlSpace="preserve"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    stroke="currentColor"
                    width="5rem"
                    height="5rem"
                    className="h-20 mb-3 text-blue-600 w-20"
                  >
                    <path
                      d="M38.028 13.749c8.148 2.342 14.116 9.856 14.116 18.753m-.479 4.315c-1.966 8.695-9.745 15.195-19.03 15.195m-4.854-.61c-8.423-2.159-14.656-9.807-14.656-18.9m.48-4.314c1.966-8.695 9.744-15.195 19.03-15.195m0 33.193v7.316m-13.1-7.9 1.674-1.674m-2.258-11.426h-7.316m7.9-13.099 1.674 1.674m11.426-9.575v7.317m11.425 2.258 1.674-1.674m7.901 13.099h-7.316M44.06 43.928l1.674 1.674"
                      fill="none"
                      strokeWidth="2"
                    ></path>
                    <circle
                      cx="32.635"
                      cy="32.502"
                      r="26"
                      fill="none"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M37.039 34.711a2.369 2.369 0 0 1 1.652 2.01l.214 2a2.177 2.177 0 0 1-2.167 2.412h-.001c-5.92 0-10.727-4.807-10.727-10.728 0-1.799.444-3.495 1.228-4.985a2.779 2.779 0 0 1 2.365-1.44c.457-.051.973-.064 1.467-.077a1.956 1.956 0 0 1 2.004 2.038l-.17 4.093a1.522 1.522 0 0 1-.934 1.34l-1.584.66a6.562 6.562 0 0 0 2.251 3.485l1.456-1.725 2.946.917z"
                      fill="none"
                      strokeWidth="2"
                    ></path>
                  </svg>
                  <h4 className="font-bold text-gray-900 text-xl">
                    24/7 Support
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MoreInfo;
