import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import SearchResults from "./pages/SearchResults";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextLabel: "#fff",
        },
      }}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/search/results" element={<SearchResults />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
