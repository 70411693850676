import AboutUsImage from "../assets/images/aboutUs.jpeg";
const AboutUs = () => {
  return (
    <section id="aboutus" className="bg-gray-100 py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            About Us
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            At Malz Logistics, we believe in making your transportation
            experience smooth, comfortable and hassle-free. With years of
            experience in the industry, we have built a reputation for providing
            exceptional logistics and car rental services to our clients.
          </p>
        </div>
        <div className="mt-20 md:flex">
          <div className="md:w-1/2">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              Our Mission
            </h3>
            <p className="text-gray-500 leading-relaxed mb-8">
              Our mission at Malz Logistics is to provide reliable and efficient
              logistics solutions that help our customers achieve their business
              goals. We are committed to delivering exceptional service,
              building strong relationships with our clients, and continually
              improving our processes and technology to stay ahead of the curve.
              At the heart of our mission is a passion for innovation and a
              dedication to excellence, as we strive to be the logistics partner
              of choice for businesses around the world.
            </p>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Our Vision</h3>
            <p className="text-gray-500 leading-relaxed mb-8">
              Our vision at Malz Logistics is to become a leading provider of
              reliable and efficient logistics solutions, delivering exceptional
              value to our clients through innovative and sustainable practices.
              We strive to continuously improve and expand our services to meet
              the evolving needs of our clients while upholding our commitment
              to ethical and responsible business practices. Our ultimate goal
              is to be recognized as a trusted partner in the global logistics
              industry, delivering unparalleled value to our clients, employees,
              and stakeholders.
            </p>
          </div>
          <div className="md:w-1/2">
            <img
              src={AboutUsImage}
              alt="About Us"
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
