import React from "react";

type Props = {};

function Testimonials({}: Props) {
  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Testimonials
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            What our customers are saying about us.
          </p>
        </div>

        <div className="mt-10">
          <div className="flex flex-wrap justify-center ">
            <div className="w-full md:w-1/2 lg:w-1/3 px-2">
              <div className="bg-white rounded-lg shadow-lg p-4">
                <p className="text-gray-700 text-base mb-4">
                  "I had a great experience using this car rental service. The
                  staff was friendly and helpful, and the car I rented was in
                  excellent condition. I will definitely use this service
                  again!"
                </p>
                <p className="text-gray-800 text-sm font-bold">James Mwale</p>
                <p className="text-gray-700 text-xs">Lusaka, Zambia</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-2">
              <div className="bg-white rounded-lg shadow-lg p-4">
                <p className="text-gray-700 text-base mb-4">
                  "I needed a car at the last minute for a business trip, and
                  this rental service was able to provide me with one quickly
                  and efficiently. The car was clean and well-maintained, and
                  the service was excellent."
                </p>
                <p className="text-gray-800 text-sm font-bold">Moses Gwena</p>
                <p className="text-gray-700 text-xs">Mazabuka, Lusaka</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-2">
              <div className="bg-white rounded-lg shadow-lg p-4">
                <p className="text-gray-700 text-base mb-4">
                  "I have used many different car rental services over the
                  years, and this is by far the best. The staff is friendly and
                  helpful, the cars are in great condition, and the prices are
                  very reasonable."
                </p>
                <p className="text-gray-800 text-sm font-bold">Mercy Mwanza</p>
                <p className="text-gray-700 text-xs">Lusaka, Zambia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
