import AboutUs from "../components/AboutUs";
import CarRentalHero from "../components/CarRentalHero";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
import MoreInfo from "../components/MoreInfo";
import NavBar from "../components/NavBar";
import ServicesContainer from "../components/ServicesContainer";
import Testimonials from "../components/Testimonials";
import VehicleRentalCategories from "../components/VehicleRentalCategories";

type Props = {};

function Home({}: Props) {
  return (
    <div>
      <NavBar />
      <CarRentalHero />
      <VehicleRentalCategories />
      <ServicesContainer />
      <MoreInfo />
      <AboutUs />
      <FAQs />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default Home;
