/* This example requires Tailwind CSS v2.0+ */
import { HiTruck } from "react-icons/hi";

const features = [
  {
    name: "Car Rental",
    description:
      "We offer a wide range of vehicles to suit all your transportation needs, whether you're looking for a car for a weekend getaway or a truck for moving furniture. Our fleet is regularly maintained to ensure that all our vehicles are in top condition and ready to hit the road.",
    icon: HiTruck,
  },
  {
    name: "Logistical Services",
    description:
      "We provide comprehensive logistical support for businesses of all sizes. From storage and distribution to inventory management and transportation, our team of experts can help streamline your operations and ensure your products are delivered on time, every time.",
    icon: HiTruck,
  },
  {
    name: "Weddings",
    description:
      "Make your special day even more memorable with our wedding car rental services. We have a variety of luxury vehicles available, from classic cars to modern limousines, to make sure you arrive in style.",
    icon: HiTruck,
  },
  {
    name: "Airport Shuttles",
    description:
      "Our airport shuttle service provides convenient and comfortable transportation to and from the airport. We offer a variety of vehicles to suit your needs, whether you're traveling solo or in a group.",
    icon: HiTruck,
  },
];

export default function ServicesContainer() {
  return (
    <div id="services" className=" overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>
        <div className="lg:text-center pb-20">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Services
          </h2>
        </div>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              What we offer.
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              The following are some of the services we offer. We are always
              open to new ideas and suggestions.
            </p>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
