import CategoryCard from "./CategoryCard";
import toyotaHilux from "../assets/images/cars/toyota-hilux-canopy-12-160-20201022-1407.jpg";
import familyVehicle from "../assets/images/cars/family.jpeg";
import groupVehicle from "../assets/images/cars/groups.jpeg";
import luxuryVehicle from "../assets/images/cars/luxury.jpeg";
import mini from "../assets/images/cars/mini.jpeg";
import suv from "../assets/images/cars/suv.jpeg";

function VehicleRentalCategories() {
  return (
    <section className="bg-gray-100 py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Vehicle Rental Categories
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Choose the category that suits your needs
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            We offer a wide range of vehicle rental categories to fit any budget
            and any type of transportation needs.
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <CategoryCard
              title={"COMMERCIAL"}
              description={
                "Tailored to the logistics needs of customers who need to move goods and freight"
              }
              imageUrl={toyotaHilux}
            />
            <CategoryCard
              title={"FAMILY"}
              description={
                "Great for smaller groups where you need a little more space but still want to watch your fuel budget"
              }
              imageUrl={familyVehicle}
            />
            <CategoryCard
              title={"GROUPS"}
              description={
                "Targeted to the needs of bigger groups who need more space"
              }
              imageUrl={groupVehicle}
            />
            <CategoryCard
              title={"SEDAN"}
              description={
                "Ideal for the traveller who can't compromise on comfort or performance"
              }
              imageUrl={luxuryVehicle}
            />
            <CategoryCard
              title={"MINI"}
              description={
                "Perfect for small groups or individuals where you would like to keep your fuel costs to a minimum"
              }
              imageUrl={mini}
            />
            <CategoryCard
              title={"SUV"}
              description={
                "The best of all worlds - carry more people and luggage with comfort and safety"
              }
              imageUrl={suv}
            />

            {/* <Link
              to="/rentals/cars"
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="px-6 py-8">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon
                    icon={faTruck}
                    className="text-indigo-600 mr-3"
                  />
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Cars
                  </h3>
                </div>
                <p className="text-base leading-6 text-gray-500">
                  Choose from a wide range of cars that fit your budget and
                  transportation needs.
                </p>
              </div>
              <div className="bg-indigo-600 px-6 py-3">
                <div className="text-sm font-medium text-white">Rent Now</div>
              </div>
            </Link>

            <Link
              to="/rentals/vans"
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="px-6 py-8">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon
                    icon={faTruck}
                    className="text-indigo-600 mr-3"
                  />
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Vans
                  </h3>
                </div>
                <p className="text-base leading-6 text-gray-500">
                  Need to transport larger groups or items? Our vans are perfect
                  for that.
                </p>
              </div>
              <div className="bg-indigo-600 px-6 py-3">
                <div className="text-sm font-medium text-white">Rent Now</div>
              </div>
            </Link>

            <Link
              to="/rentals/trucks"
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="px-6 py-8">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon
                    icon={faTruck}
                    className="text-indigo-600 mr-3"
                  />
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Trucks
                  </h3>
                </div>
                <p className="text-base leading-6 text-gray-500">
                  Moving large items or need a vehicle to transport goods? Our
                  trucks can handle it.
                </p>
              </div>
              <div className="bg-indigo-600 px-6 py-3">
                <div className="text-sm font-medium text-white">Rent Now</div>
              </div>
            </Link>
            <Link
              to="/rentals/trucks"
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="px-6 py-8">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon
                    icon={faTruck}
                    className="text-indigo-600 mr-3"
                  />
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Buses
                  </h3>
                </div>
                <p className="text-base leading-6 text-gray-500">
                  Need to transport a large group of people? Our buses can
                  handle it.
                </p>
              </div>
              <div className="bg-indigo-600 px-6 py-3">
                <div className="text-sm font-medium text-white">Rent Now</div>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default VehicleRentalCategories;
