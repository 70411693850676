import React from "react";

const FAQs = () => {
  return (
    <div id="faq" className="bg-white ">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Read through our FAQ's
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Here are some of our FAQs. If you have any other questions you'd
            like answered please feel free to contact us.
          </p>
        </div>

        <div className="flex flex-row pt-20">
          <div className="w-1/3 pr-4">
            <h2 className="text-lg font-medium mb-4">Before Your Rental</h2>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                What documents do I need to rent a car?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                You will need a valid driver's license, credit card, and proof
                of insurance. Some car rental companies may require additional
                documentation.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                What is the minimum age to rent a car?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                The minimum age to rent a car is usually 21, but some car rental
                companies may require drivers to be at least 25 years old.
              </p>
            </div>
          </div>
          <div className="w-1/3 px-4">
            <h2 className="text-lg font-medium mb-4">During Your Rental</h2>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                Can I extend my rental period?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                Yes. You are welcome to extend your rental telephonically or via
                email. However, you will be required to physically present
                yourself and the vehicle on every second extension.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                What should I do if I have an accident?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                If you are involved in an accident, call the local police and
                the car rental company's emergency number right away. Follow the
                instructions given by the car rental company and provide all
                necessary information about the accident.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                Can I add an additional driver to my rental?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                Yes, you can usually add an additional driver to your rental for
                an additional fee. The additional driver will need to meet the
                same rental requirements as the primary driver.
              </p>
            </div>
          </div>
          <div className="w-1/3 pl-4">
            <h2 className="text-lg font-medium mb-4">Ending Your Rental</h2>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                Where do I return the car?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                The return location will be specified in your rental agreement.
                If you are unsure, please contact our customer support team for
                assistance.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                What if I return the car late?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                If you return the car late, you may be subject to a late fee.
                The amount of the fee may vary depending on the rental car
                company and the terms of your rental agreement. It is important
                to communicate with the rental car company if you anticipate
                returning the car late or if you are having trouble returning
                the car on time.
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-gray-800 font-medium mb-2">
                What do I do if I get a parking ticket?
              </h3>
              <p className="text-gray-600 leading-relaxed">
                If you receive a parking ticket, you will be responsible for
                paying the fine. You can pay the fine directly to the parking
                authority or you can pay the fine to the car rental company when
                you return the car.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
    //   <div className="max-w-7xl mx-auto">
    //     <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
    //       Frequently Asked Questions
    //     </h2>
    //     <div className="mt-12">
    //       <dl className="space-y-10">
    //         <div>
    //           <dt className="text-lg leading-6 font-medium text-gray-900">
    //             Before Your Rental
    //           </dt>
    //           <dd className="mt-2 text-base text-gray-500">
    //             <p className="mb-4">
    //               Here are some common questions asked before renting a car:
    //             </p>
    //             <dl className="space-y-4">
    //               <div className="border-b border-gray-200 pb-4">
    //                 <dt className="text-md leading-5 font-medium text-gray-900">
    //                   What documents do I need to rent a car?
    //                 </dt>
    //                 <dd className="mt-2 text-gray-500">
    //                   You will need a valid driver's license, credit card, and
    //                   proof of insurance. Some car rental companies may require
    //                   additional documentation.
    //                 </dd>
    //               </div>
    //               <div className="border-b border-gray-200 pb-4">
    //                 <dt className="text-md leading-5 font-medium text-gray-900">
    //                   How old do I need to be to rent a car?
    //                 </dt>
    //                 <dd className="mt-2 text-gray-500">
    //                   The minimum age to rent a car is usually 21, but some car
    //                   rental companies may require drivers to be at least 25
    //                   years old.
    //                 </dd>
    //               </div>
    //             </dl>
    //           </dd>
    //         </div>

    //         <div>
    //           <dt className="text-lg leading-6 font-medium text-gray-900">
    //             During Your Rental
    //           </dt>
    //           <dd className="mt-2 text-base text-gray-500">
    //             <p className="mb-4">
    //               Here are some common questions asked during the rental period:
    //             </p>
    //             <dl className="space-y-4">
    //               <div className="border-b border-gray-200 pb-4">
    //                 <dt className="text-md leading-5 font-medium text-gray-900">
    //                   What do I do if I have an accident?
    //                 </dt>
    //                 <dd className="mt-2 text-gray-500">
    //                   If you are involved in an accident, call the local police
    //                   and the car rental company's emergency number right away.
    //                   Follow the instructions given by the car rental company
    //                   and provide all necessary information about the accident.
    //                 </dd>
    //               </div>
    //               <div className="border-b border-gray-200 pb-4">
    //                 <dt className="text-md leading-5 font-medium text-gray-900">
    //                   Can I add an additional driver to my rental?
    //                 </dt>
    //                 <dd className="mt-2 text-gray-500">
    //                   Yes, you can usually add an additional driver to your
    //                   rental for an additional fee. The additional driver will
    //                   need to meet the same rental requirements as the primary
    //                   driver.
    //                 </dd>
    //               </div>
    //             </dl>
    //           </dd>
    //         </div>

    //         <div>
    //           <dt className="text-lg leading-6 font-medium text-gray-900">
    //             Ending Your Rental
    //           </dt>
    //           <dd className="mt-2 text-base text-gray-500">
    //             <p className="mb-4">
    //               Here are some common questions asked when returning a car:
    //             </p>
    //             <dl className="space-y-4">
    //               <div className="border-b border-gray-200 pb-4">
    //                 <dt className="text-md leading-5 font-medium text-gray-900">
    //                   What do I do if I get a parking ticket?
    //                 </dt>
    //                 <dd className="mt-2 text-gray-500">
    //                   If you receive a parking ticket, you will be responsible
    //                   for paying the fine. You can pay the fine directly to the
    //                   parking authority or you can pay the fine to the car
    //                   rental company when you return the car.
    //                 </dd>
    //               </div>
    //               <div className="border-b border-gray-200 pb-4">
    //                 <dt className="text-md leading-5 font-medium text-gray-900">
    //                   What do I do if I get a speeding ticket?
    //                 </dt>
    //                 <dd className="mt-2 text-gray-500">
    //                   If you receive a speeding ticket, you will be responsible
    //                   for paying the fine. You can pay the fine directly to the
    //                   police department or you can pay the fine to the car
    //                   rental company when you return the car.
    //                 </dd>
    //               </div>
    //             </dl>
    //           </dd>
    //         </div>
    //       </dl>
    //     </div>
    //   </div>
    // </div>
  );
};

export default FAQs;
