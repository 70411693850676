import React, { useState } from "react";
import { Form, Input, DatePicker, Button, Tooltip, TimePicker } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";

interface SearchFormProps {
  initialValues: {
    pickupLocation: string;
    pickupDate: string;
    returnDate: string;
    pickupTime: string;
    carType: string;
  };
  onSubmit: (values: any) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFinish = (values: any) => {
    setIsSubmitting(true);
    onSubmit(values);
    setIsSubmitting(false);
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={initialValues}
      layout="vertical"
      size="middle"
      className="bg-gray-100 rounded-lg px-8 py-4 flex flex-wrap justify-around items-center"
    >
      <div className="w-full md:w-2/12 mb-4 md:mb-0">
        <Form.Item name="pickupLocation">
          <Input placeholder="Enter pickup location" className="w-full" />
        </Form.Item>
      </div>
      <div className="w-full md:w-2/12 mb-4 md:mb-0">
        <Form.Item name="pickupDate">
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select pickup date"
            className="w-full"
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
          />
        </Form.Item>
      </div>
      <div className="w-full md:w-2/12 mb-4 md:mb-0">
        <Form.Item name="returnDate">
          <DatePicker
            format="YYYY-MM-DD"
            placeholder="Select return date"
            className="w-full"
            disabledDate={(current) =>
              !form.getFieldValue("pickupDate") ||
              current < moment(form.getFieldValue("pickupDate")).startOf("day")
            }
          />
        </Form.Item>
      </div>
      <div className="w-full md:w-2/12 mb-4 md:mb-0">
        <Form.Item name="pickupTime">
          <TimePicker className="w-full" format="HH:mm" />
        </Form.Item>
      </div>

      <div className="w-full md:w-1/12">
        <Form.Item>
          <Button
            type="default"
            htmlType="submit"
            loading={isSubmitting}
            className="w-full"
            size="middle"
          >
            Search
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SearchForm;
