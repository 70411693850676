import React, { useState } from "react";
import { Card, Checkbox, Col, Divider, Row, Typography } from "antd";
import { StarFilled } from "@ant-design/icons";
import CarRentalCard from "./CarRentalCard";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import RentalCar from "./RentalCar";
import CarResultCard from "./CarResultCard";
import { CheckOutlined } from "@ant-design/icons";
const CheckboxGroup = Checkbox.Group;

const plainOptions = ["Apple", "Pear", "Orange"];
const defaultCheckedList = ["Apple", "Orange"];

const { Title, Text } = Typography;

const CarRentalSearchResults = () => {
  const data = [
    {
      id: "1",
      name: "Toyota Camry",
      type: "Sedan",
      passengers: 5,
      price: 45,
      transmissionType: "Automatic",
      rating: 4.5,
      image:
        "https://www.woodford.co.za/Content/images/vehicles/toyota-starlet-2022-15-xs-auto-40-275-20220106-1041.jpg",
      hasAC: true,
      rentalAmount: 700,
      services: ["Airport Pickup", "24/7 Support", "Free Cancellation"],
    },
    {
      id: "2",
      name: "Honda Civic",
      type: "Sedan",
      passengers: 5,
      price: 50,
      transmissionType: "Automatic",
      rating: 4.2,
      image:
        "https://www.woodford.co.za/Content/images/vehicles/toyota-starlet-2022-15-xs-auto-40-275-20220106-1041.jpg",
      hasAC: true,
      rentalAmount: 700,
      services: ["Airport Pickup", "24/7 Support", "Free Cancellation"],
    },
    {
      id: "3",
      name: "Nissan Altima",
      type: "Sedan",
      passengers: 5,
      price: 48,
      transmissionType: "Automatic",
      rating: 4.0,
      image:
        "https://www.woodford.co.za/Content/images/vehicles/toyota-starlet-2022-15-xs-auto-40-275-20220106-1041.jpg",
      hasAC: true,
      rentalAmount: 700,
      services: ["Airport Pickup", "24/7 Support", "Free Cancellation"],
    },
  ];

  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const [showMoreInformation, setShowMoreInformation] = useState(false);
  const [showEmailQuote, setShowEmailQuote] = useState(false);
  const [emailQuoteValue, setEmailQuoteValue] = useState("");
  const [emailQuoteSuccess, setEmailQuoteSuccess] = useState(false);
  const [emailQuoteError, setEmailQuoteError] = useState("");

  const handleMoreInformationClick = () => {
    setShowMoreInformation((prevState) => !prevState);
  };

  const handleEmailQuoteClick = () => {
    setShowEmailQuote((prevState) => !prevState);
  };

  const handleEmailQuoteSubmit = () => {
    // Add email quote submit logic here
    setEmailQuoteSuccess(true);
  };

  return (
    <div className="container mx-auto p-8">
      <div className="lg:text-center">
        <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
          Vehicle Rental Categories
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Choose the category that suits your needs
        </p>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          We offer a wide range of vehicle rental categories to fit any budget
          and any type of transportation needs.
        </p>
      </div>
      <div className="container py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/5 ">
            <h2 className="text-lg font-bold mb-2">Category</h2>
            <FilterCheckbox label="SUV" />
            <FilterCheckbox label="Sedan" />
            <FilterCheckbox label="Van" />
            <FilterCheckbox label="Hatchback" />
            <FilterCheckbox label="Pickup" />

            <h2 className="text-lg font-bold mb-2 mt-4">Transmission</h2>
            <FilterCheckbox label="Automatic" />
            <FilterCheckbox label="Manual" />

            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
              Clear All Filters
            </button>
          </div>
          <div className="md:w-2/3">
            <h2 className="text-lg font-bold mb-4">Results</h2>
            <div>
              {data.map((car) => (
                <div className="mb-4 rounded">
                  <div className="grid grid-cols-1 sm:grid-cols-4">
                    <div className="bg-gray-100 col-span-1 sm:col-span-3 p-3">
                      <div className="grid-cols-4 gap-1 sm:grid-cols-4 flex justify-between">
                        <div className="my-2 col-span-2 sm:col-span-2 text-lg font-semibold text-blue-600">
                          Kia Picanto / Similar
                        </div>
                        <div className="my-2 col-span-1 sm:col-span-2 text-md text-gray-500">
                          Hatchback
                        </div>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-8">
                        <div className="text-xs font-semibold sm:col-span-2 uppercase space-x-1">
                          <div>Manual</div>
                          <div>4 People</div>
                          <div>2 Bags</div>
                          <div>Aircon</div>
                        </div>
                        <div className="col-span-1 sm:col-span-4">
                          <img src={car.image} />
                        </div>
                        <div className="col-span-1 sm:col-span-2 text-xs hidden sm:block">
                          <div className="text-md font-semibold">
                            Included For Free:
                          </div>
                          <ul className="list-outside">
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              Airport Pickup
                            </li>
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              24/7 Support
                            </li>
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              Free Cancellation
                            </li>
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              Roadside assistance
                            </li>
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              Battery jump-start
                            </li>
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              Flat tire change
                            </li>
                            <li>
                              <CheckOutlined className="text-md font-semibold" />{" "}
                              2hr breakdown replacement
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-200 col-span-1 sm:col-span-1 p-3 justify-items-center">
                      <div className="text-xs">
                        <span className="text-blue-600">Free Cancellation</span>{" "}
                        until 24 hours before pickup
                      </div>
                      <div className="text-right">
                        <div className=" text-md pt-8 text-gray-800">
                          K700.00/ day
                        </div>
                        <div className=" text-2xl font-bold">K2,100.00</div>
                        <div className=" text-xs text-gray-500">
                          total for 3 days
                        </div>
                        <div className="mt-4 items-center">
                          <button className="bg-blue-500 hover:bg-blue-700 w-full shadow-md text-white font-bold py-2 px-4 rounded">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarRentalSearchResults;

const FilterCheckbox: React.FC<{ label: string }> = ({ label }) => {
  const [checked, setChecked] = useState(false);

  return (
    <label className="flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="mr-2 cursor-pointer"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      {label}
    </label>
  );
};
